<template>
  <div class="available-in-holder">
    <div
      class="select-holder"
      tabindex="0"
      data-testid="available-in-select-holder"
      @click="toggleOverlay"
    >
      <el-tag v-if="isEmptyArray(modelValue)">
        {{ $t('components.available_in.nowhere') }}
      </el-tag>
      <el-tag v-if="modelValue === null">
        {{ $t('components.available_in.everywhere') }}
      </el-tag>
      <el-tag
        v-for="location in locationChips"
        v-else
        :key="location.id"
        class="location-tag"
        size="small"
      >
        {{ location.name }}
      </el-tag>
    </div>

    <modal
      v-model="isModalOpen"
      name="locations"
      adaptive
      :min-width="800"
      :min-height="600"
      @before-open="beforeOpen"
    >
      <el-container class="h-full flex flex-col justify-between min-h-0">
        <el-header class="flex justify-between w-full items-center">
          <span />
          <h2 class="m-0" v-text="$t('components.available_in.label')" />
          <div
            class="cursor-pointer text-lg text-center text-gray-600"
            @click="isModalOpen = false"
          >
            <el-icon><Close /></el-icon>
          </div>
        </el-header>

        <el-main class="p-4 overflow-auto">
          <label
            class="label"
            v-text="$t('components.available_in.locations.label')"
          />
          <el-select
            v-model="localValue"
            v-cancel-read-only
            clearable
            multiple
            filterable
            class="multi-select-full locations-select"
            :placeholder="$t('components.available_in.locations.placeholder')"
            data-testid="branch-select"
            @clear="() => $emit('update:modelValue', null)"
          >
            <el-option
              v-for="branch in branchesList"
              :id="branch.value"
              :key="branch.value"
              :label="branch.label"
              :value="branch.value"
            />
          </el-select>

          <label
            class="label"
            v-text="$t('components.available_in.branch_groups.label')"
          />

          <el-select
            v-model="currentBranchGroups"
            v-cancel-read-only
            clearable
            multiple
            filterable
            class="multi-select-full branch-groups-select"
            data-testid="branch-group-select"
            :placeholder="
              $t('components.available_in.branch_groups.placeholder')
            "
          >
            <el-option
              v-for="group in branchGroupsList"
              :id="group.value"
              :key="group.value"
              :label="group.label"
              :value="group.value"
            />
          </el-select>
        </el-main>
        <div class="p-4">
          <el-button
            id="available-everywhere"
            @click="() => $emit('update:modelValue', null)"
          >
            {{ $t('components.available_in.make_available_everywhere') }}
          </el-button>
          <el-button
            id="available-nowhere"
            @click="() => $emit('update:modelValue', [])"
          >
            {{ $t('components.available_in.make_available_nowhere') }}
          </el-button>
        </div>
      </el-container>
    </modal>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import unique from 'just-unique'
import flatten from 'just-flatten-it'

export default {
  name: 'AvailableInLegacy',
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: undefined
    },
    resources: {
      type: Object,
      required: true
    },
    showItemsLimit: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      visible: false,
      isModalOpen: false
    }
  },
  computed: {
    localValue: {
      /* We need to translate the meaning of [] or null from the form value to the interpretation in the applications. An empty array means "nowhere" while null means "everywhere". To make it clear to the user, the select field tags need to display "nowhere" whenever necessary. */
      get() {
        if (this.modelValue === null || this.modelValue === undefined) {
          return [this.$t('components.available_in.everywhere')] // null -> []
        } else if (this.isEmptyArray(this.modelValue)) {
          return [this.$t('components.available_in.nowhere')] // [] -> ['nowhere']
        } else {
          return this.modelValue
        }
      },
      set(vals) {
        if (this.isEmptyArray(vals)) {
          // when the user clears the select field, we assume he wants to default to "everywhere", rather than "nowhere"
          this.$emit('update:modelValue', null) // [] => null
        } else {
          // if any other value is present, remove "nowhere" and "everywhere"
          const nowhere = this.$t('components.available_in.nowhere')
          const everywhere = this.$t('components.available_in.everywhere')
          const stripped = vals.filter((v) => v !== nowhere && v !== everywhere)

          this.$emit('update:modelValue', stripped)
        }
      }
    },
    branchesList() {
      const branches = safeGet(this.resources, 'branches') || []
      return branches.map((branch) => {
        return {
          value: branch.id,
          label: branch.name
        }
      })
    },
    branchGroupsListCleaned() {
      // remove branch groups without branches
      const groups = safeGet(this.resources, 'branchGroups') || []
      return groups.filter((group) => group.branches && group.branches.length)
    },
    branchGroupsList() {
      return this.branchGroupsListCleaned.map((branch) => {
        return {
          value: branch.id,
          label: branch.name
        }
      })
    },
    locationChips() {
      if (!this.modelValue) return []

      const branches = safeGet(this.resources, 'branches') || []
      const value = this.showItemsLimit
        ? this.modelValue.slice(0, this.showItemsLimit)
        : this.modelValue

      const chips = value
        .map((item) => {
          const b = branches.find((branch) => item === branch.id)
          return {
            id: item,
            name: (b || {}).name,
            exists: !!b
          }
        })
        .filter((item) => item.exists)

      const restNumber = this.showItemsLimit
        ? this.modelValue.length - this.showItemsLimit
        : 0

      const restElement =
        restNumber > 0 ? [{ name: `+ ${restNumber}`, id: Date.now() }] : []

      return this.showItemsLimit ? [...chips, ...restElement] : chips
    },
    currentBranchGroups: {
      // since we do not have ACL implemented yet, we will make a fake branch group ACL
      // by checking whether available branch groups have branch included. If this is true we will populate that list.
      //
      // If the user sets a branch group we will take the branches and add them to the currently available locations
      get() {
        return this.branchGroupsListCleaned
          .filter((item) => {
            const { branches } = item
            if (!branches || !Array.isArray(branches) || !branches.length) {
              return false
            } else {
              return branches.every(
                (elem) => (this.modelValue || []).indexOf(elem) > -1
              )
            }
          })
          .map((item) => {
            return item.id
          })
      },
      set(v) {
        const old = this.currentBranchGroups
        const toDeleteBranchGroups = old.filter((group) => !v.includes(group))

        let result

        const branchesFromSelectedGroups = flatten(
          (safeGet(this.resources, 'branchGroups') || [])
            .filter((group) => v.includes(group.id))
            .filter((group) => Array.isArray(group.branches))
            .map((group) => group.branches)
        )

        if (
          Array.isArray(toDeleteBranchGroups) &&
          toDeleteBranchGroups.length
        ) {
          const branchesFromDeletedGroups = flatten(
            (safeGet(this.resources, 'branchGroups') || [])
              .filter((group) => toDeleteBranchGroups.includes(group.id))
              .filter((group) => Array.isArray(group.branches))
              .map((group) => group.branches)
          )

          /* These are the branches that need to be removed, i.e. branches from the deleted groups that are not part of any other currently selected branch groups (as there might be overlapping) */
          const branchesToBeDeleted = branchesFromDeletedGroups.filter(
            (branch) => !branchesFromSelectedGroups.includes(branch)
          )

          result = [...(this.modelValue || [])].filter(
            (branch) => !branchesToBeDeleted.includes(branch)
          )
        } else {
          result = unique([
            ...(this.modelValue || []),
            ...(branchesFromSelectedGroups || [])
          ])
        }

        // default to null if no branches are selected to signify "everywhere"
        if (result.length) {
          this.$emit('update:modelValue', result)
        } else {
          this.$emit('update:modelValue', null)
        }
      }
    }
  },
  methods: {
    beforeOpen(event) {},
    toggleOverlay() {
      this.isModalOpen = true
    },
    isEmptyArray(value) {
      return Array.isArray(value) && !value.length
    }
  }
}
</script>

<style lang="css" scoped>
.available-in-holder {
  width: 100%;
}

.select-holder {
  width: 100%;
  -webkit-appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--label-text-color);
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  overflow-x: auto;
}

.el-header {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.label {
  display: block;
  padding-bottom: 1rem;
}

.multi-select-full {
  width: 100%;
  margin-bottom: 3rem;
}

.location-tag {
  margin-right: 0.5rem;
}

.multi-select-full :deep(input) {
  min-height: 4rem;
}
</style>
